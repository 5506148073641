import React from 'react';
import logo from '../images/logo.png'
import EnvironmentVariables from '../utils/EnvironmentVariables';
import { Title, Text } from './Typography';
import isMobileFunc from '../utils/DisplaySettings';

const Home = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const [isMobile, setIsMobile] = React.useState(isMobileFunc());

    return (
        <div style={{
            marginTop: '5vh', // Reduced from 20vh to move content up
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start', // Changed from center to flex-start
            minHeight: isMobile ? 'calc(100vh - 120px)' : '100vh',
            width: '100%',
            padding: '0 20px',
            boxSizing: 'border-box'
        }}>
            <img 
                src={logo} 
                alt="Logo"
                style={{
                    width: isMobile ? '100%' : '50%',
                    marginBottom: '20px',
                    maxWidth: '600px'
                }}
            />
            {!code && (
                <div style={{
                    width: '100%',
                    maxWidth: '600px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <h1 style={{ 
                        fontSize: '2.5rem', 
                        marginBottom: '30px', 
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        The thrill of concerts from anywhere, anytime
                    </h1>
                    <button onClick={() => window.location.href = EnvironmentVariables.AuthURL}>
                        Sign up/login
                    </button>
                </div>
            )}
        </div>
    );
}

export default Home;