import React, { useEffect, useState } from 'react';
import InfoAPI from './InfoAPI';
import SessionUserUI from './SessionUserUI';
import { Title, Text } from './Typography';

// Navigation in open sessions

async function fetchSessionList(setSessionButtons) {
  const requestBody = JSON.stringify({
    info_type: "active_sessions"
  });

  const data = await InfoAPI(requestBody);
  console.log('Session list:', data.body);
  const sessionsListParsed = JSON.parse(data.body);
  const sessionButtons = sessionsListParsed.map((session, index) => (
    <button key={index}>{session}</button>
  ));
  setSessionButtons(sessionButtons);
  window.scrollTo({ top: 0, behavior: 'smooth' });
  }

const Attend = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
  const [sessionButtons, setSessionButtons] = useState([]);
  const [showUserUI, setshowUserUI] = useState(false);
  const [showSessionsList, setShowSessionsList] = useState(true);

  useEffect(() => {
    fetchSessionList(setSessionButtons);
  }, []);

  const handleSessionButtonClick = (session) => {
    localStorage.setItem('selectedSession', session);
    setShowSessionsList(false);
    setshowUserUI(true);
  };

  return (
    <div>
      {showSessionsList && (
        <>
          <p style={{ textAlign: 'center' }}>Please select a session from the list below:</p>
            <div id="sessionsList" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
              {sessionButtons.map((sessionButton, index) => (
                <button
                  key={index}
                  onClick={() => handleSessionButtonClick(sessionButton.props.children)}
                  style={{ width: '200px', height: '100px', margin: '20px' }}
                >
                  {sessionButton.props.children}
                </button>
              ))}
            </div>
        </>
      )}

      {showUserUI && (
        <SessionUserUI />
      )}
    </div>
  );
};

export default Attend;