import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import UserAPI from './UserAPI';
import EmotionScanner from './EmotionScanner';
import SongsMenu from './SongsMenu';
import SettingsMenuCreate from './SettingsMenuCreate';
import { Title, Text } from './Typography';

const Create = () => {
  const { idToken, accessToken } = useAuth();
  const [sessionOnGoing, setSessionOnGoing] = useState(null);
  const [settingsMenu, setSettingsMenu] = useState(false);
  const [songsMenu, setSongsMenu] = useState(false);

  const getSessionStatus = async () => {
    const requestBody = JSON.stringify({
      'idToken': localStorage.getItem('idToken'),
      'request_type': 'getsessionstatus'
    });
    // Call API for fetching session status
    const jsonData = await UserAPI(requestBody);
    const result = JSON.parse(jsonData.body);
    if (result.session_ongoing) {
      setSessionOnGoing(true);
    } else {
      setSessionOnGoing(false);
    }
  };



  useEffect(() => {
    getSessionStatus();
  }, []);

  const startstopSession = async () => {
    const requestBody = JSON.stringify({
      'idToken': localStorage.getItem('idToken'),
      'request_type': 'startstopsession'
    });

    // Call API for starting or stopping a session
    const jsonData = await UserAPI(requestBody);
    const result = JSON.parse(jsonData.body);

    if (result.session_ongoing) {
      // start session
      setSessionOnGoing(true);
    } else {
      // stop session
      setSessionOnGoing(false);
    }
  };




  const openSettings = async () => {
    setSettingsMenu(!settingsMenu);
  };

  const openSongsMenu = async () => {
    setSongsMenu(!songsMenu);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', padding: '20px' }}>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
        <button onClick={startstopSession} id="sessionButton">
          {sessionOnGoing ? 'Stop Session' : 'Start Session'}
        </button>
        <button onClick={openSongsMenu}>{songsMenu ? 'Close song list' : 'Open song list'}</button>
        <button onClick={openSettings}>{settingsMenu ? 'Close settings' : 'Settings'}</button>
      </div>

      {settingsMenu && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}>
          <SettingsMenuCreate />
        </div>
      )}

      {songsMenu && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}>
          <SongsMenu />
        </div>
      )}

      {sessionOnGoing && (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <EmotionScanner />
            </div>
      )}

      

      <div style={{ margin: '20px 0' }}></div>

      
    </div>
  );
};

export default Create;
