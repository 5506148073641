import { useEffect, useState, useRef } from 'react';
import { useAuth } from './AuthContext';
import UserSongsMenu from './UserSongsMenu';
import UserAPI from './UserAPI';
import TuneCoinImage from '../images/tunecoin.png';
import InfoAPI from './InfoAPI';
import TransactionAPI from './TransactionAPI';
import ImageAnalysisAPI from './ImageAnalysisAPI';
import React from 'react';
import isMobileFunc from '../utils/DisplaySettings';
import Impressions from './Impressions';
import { Title, Text } from './Typography';
import { refreshTime } from '../utils/DisplaySettings';

function generateRandomFileName() {
    const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
    const filename = 'frame' + randomNumber + '.jpg';
    return filename;
}

const SessionUserUI = () => {
    const [stream, setStream] = useState(null);
    const videoRef = useRef(null);
    const { idToken } = useAuth();
    const intervalIdRef = useRef(null);
    const [emotions, setEmotions] = useState([]);
    const [scan, setScan] = useState(false);
    const selectedSession = localStorage.getItem('selectedSession');
    const [songsMenu, setSongsMenu] = useState(false);
    const scanFrequency = 1000; // in milliseconds
    const [credits, setCredits] = useState(null);
    const [points, setPoints] = useState(null);
    const [lastSongName, setLastSongName] = useState(null);
    const [lastArtist, setLastArtist] = useState(null);
    const minFontSize = 12; // Minimum font size
    const [embedding, setEmbedding] = useState(null);
    const [embeddingURL, setEmbeddingURL] = useState(null);
    const [frameWidth, setFrameWidth] = useState('560');
    const [frameheight, setFrameHeight] = useState('315');


    useEffect(() => {
        const getMedia = async () => {
            try {
                const mediaStream = await navigator.mediaDevices.getUserMedia({ 
                    video: {
                        width: { ideal: 200 }
                    }
                });
                setStream(mediaStream);
            } catch (err) {
                if (err.name === 'NotAllowedError') {
                    alert('Permission to access camera was denied. Please allow access to the camera and try again.');
                } else if (err.name === 'NotFoundError') {
                    alert('No camera device found. Please ensure a camera is connected and try again.');
                } else if (err.name === 'NotReadableError') {
                    alert('Camera is already in use by another application. Please close other applications and try again.');
                } else {
                    alert('Error accessing media devices: ' + err.message);
                }
            }
        };

        const getEmbedding = async () => {
            const bodyData = JSON.stringify({
                'info_type': 'embedding',
                'session_name': localStorage.getItem('selectedSession')
            });
            const jsonData = await InfoAPI(bodyData);
            const result = JSON.parse(jsonData.body);
            const embeddingVar = result.embeddingVar;
            console.log('Embedding:', embeddingVar);
            if (embeddingVar==0) {
                setEmbedding(false);
            }
            else {
                setEmbedding(true);
            }
            setEmbeddingURL(await getEmbeddingURL());
        }

        getMedia();
        getEmbedding();
        return () => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
        };
    }, []);


    

    const AnalyseImage = async (filename, sessionname) => {
        console.log('Analyzing image:', filename);
        const bodyData = JSON.stringify({
            'filename': filename,
            'sessionname': sessionname,
            'idToken': idToken
        });
        const jsonData = await ImageAnalysisAPI.AnalyseImageAPI(bodyData);
        if (jsonData.statusCode == 200) {
            const result = JSON.parse(jsonData.body);
            const emotions= result.emotions;
            console.log('Image analysis result:', emotions);
            console.log('Rekognition raw data:', JSON.parse(jsonData.rekognition));
            setEmotions(emotions);
        }
        
    }

    const handleCapture = async () => {
        const idToken = localStorage.getItem('idToken');
        const video = videoRef.current;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
        const refVideoHeight = 200; // in pixels
        context.drawImage(video, 0, 0, refVideoHeight * canvas.width / canvas.height, refVideoHeight);
        canvas.toBlob(async blob => {
            const filename = generateRandomFileName();
            const file = new File([blob], filename, { type: 'image/jpg' });
            const sessionname = localStorage.getItem('selectedSession');
            await ImageAnalysisAPI.UploadImageAPI(filename, file, idToken);
            await AnalyseImage(filename, sessionname);
        }, 'image/jpg');
    };

    const handleScan = () => {
        
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
        
        if (!scan) {
            intervalIdRef.current = setInterval(handleCapture, scanFrequency);
        } else {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
        setScan(!scan);
    };

    const openSongsMenu = async () => {
        setSongsMenu(!songsMenu);
      };

    const getCredits = async () => {
        const bodyData = JSON.stringify({
            'idToken': idToken,
            'request_type': 'getcredits'
        });
        const jsonData = await UserAPI(bodyData);
        if (jsonData.body){
            const result = JSON.parse(jsonData.body);
            const credits = result.credits;
            setCredits(credits);
        }
        setTimeout(() => {
            getCredits();
        }, refreshTime);
    };

    const getPoints = async () => {
        const bodyData = JSON.stringify({
            'idToken': idToken,
            'info_type': 'user',
            'session_name': selectedSession
        });
        console.log('Points bodyData:', bodyData);
        const jsonData = await InfoAPI(bodyData);
        if (jsonData.body){
            const result = JSON.parse(jsonData.body);
            setPoints(result[1]);
        }
        setTimeout(() => {
            getPoints();
        }, 2000);
    }

    const getLastRequest = async () => {
        const bodyData = JSON.stringify({
            'idToken': idToken,
            'type': 'lasttransaction'
        });
        const jsonData = await TransactionAPI(bodyData);
        if (jsonData.body){
            const result = JSON.parse(jsonData.body);
            const artist= result.artist;
            const song_name= result.song_name;
            console.log('Last request:', artist, song_name);
            if (artist) {
                setLastArtist(artist.replace("%q", "'"));
                setLastSongName(song_name.replace("%q", "'"));
            }
        }
        setTimeout(() => {
            getLastRequest();
        }, refreshTime);
    }

    const getEmbeddingURL = async () => {
        const bodyData = JSON.stringify({
            'session_name': localStorage.getItem('selectedSession'),
            'info_type': 'embedding'
        });
        const jsonData = await InfoAPI(bodyData);
        const result = JSON.parse(jsonData.body);
        console.log('Embedding result:', result);
        const embeddingURL = result.embed_URL;
        console.log('Embedding URL:', embeddingURL);
        
        return embeddingURL;
        
    }

    useEffect(() => {
        console.log(isMobileFunc());
        getCredits();
        getPoints();
        getLastRequest();
    }, []);
            
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px', marginRight: '20px' }}>
            {embedding && (
                <div id="embedding" style={{ display: 'flex', justifyContent: 'center' }}>
                    <iframe
                        width={frameWidth}
                        height={frameheight}
                        src={embeddingURL} 
                        title="Video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            )}
            
            <div id="info_tab" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <p>Selected Session: {selectedSession}</p>
                </div>
                <div style={{ margin: '0 10px' }}></div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p>{points !== null ? `Pts : ${points}` : 'Pts : 0'}</p>
                    <div style={{ margin: '0 10px' }}></div>
                    <img src={TuneCoinImage} alt="TuneCoin" style={{ width: '1em', height: '1em' }} />
                    <p>{credits !== null ? `${credits}` : 'Loading credits...'}</p>
                </div>
                <div style={{ margin: '0 10px' }}></div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <p>Requested: {lastSongName !== null ? lastSongName+' by '+lastArtist : 'None'}</p>
                </div>
            </div>

            <div id="buttons" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                {/* <button onClick={handleCapture} id="captureButton">Capture</button> */}
                <button onClick={handleScan} id="scanButton">{scan ? 'Stop' : 'Capture'}</button>
                <button onClick={openSongsMenu}>{songsMenu ? 'Close song list' : 'Open song list'}</button>
            </div>
            <div style={{ height: '20px' }}></div>
            <div id="emotions" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                <div id="webcam" style={{ display: 'flex', justifyContent: 'center' }}>
                        {stream ? (
                            <video
                                autoPlay
                                ref={video => {
                                    if (video && !video.srcObject) {
                                        video.srcObject = stream;
                                        videoRef.current = video;
                                    }
                                }}
                            />
                        ) : (
                            <p>Loading...</p>
                        )}
                </div>
                <div id='emotiondisplay' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {Object.entries(emotions).map(([emotionType, confidence], index) => {
                        const fontSize = Math.max(minFontSize, confidence);
                        return (
                            <div key={index} style={{ fontSize: `${fontSize}px`, textAlign: 'center' }}>
                                {emotionType}
                            </div>
                        );
                    })}
                </div>
                
                
            </div>
            <div style={{ height: '20px' }}></div>
            {songsMenu && (
                    <UserSongsMenu componentWidth={'20px'} />
                )}
            <Impressions />
        </div>
    );
};

export default SessionUserUI;