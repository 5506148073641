import styled from 'styled-components';

export const Title = styled.h1`
  font-size: clamp(1.5rem, 5vw, 3rem);
  margin-bottom: 1rem;
`;

export const Subtitle = styled.h2`
  font-size: clamp(1.25rem, 4vw, 2.5rem);
  margin-bottom: 0.875rem;
`;

export const Text = styled.p`
  font-size: clamp(1rem, 2vw, 1.25rem);
  line-height: 1.5;
  margin-bottom: 1rem;
`;

export const SmallText = styled.span`
  font-size: clamp(0.875rem, 1.5vw, 1rem);
`; 


