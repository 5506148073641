const Environment = "test"; // dev0, dev1, test

const baseURLs = {
    dev0: {
        AuthURL: 'https://d3o5hrtbl653it.auth.eu-west-3.amazoncognito.com/oauth2/authorize?client_id=3ng1jhbo6oemarms0uc9mhadak&response_type=code&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fcallback',
        CognitoURL: 'https://d3o5hrtbl653it.auth.eu-west-3.amazoncognito.com/oauth2/token',
        RedirectURI: 'http://localhost:3000/callback',
        ClientID: '3ng1jhbo6oemarms0uc9mhadak',
        UploadImageAPI_URL: 'https://9q1r5ay8di.execute-api.eu-west-3.amazonaws.com/dev0/faces-pictures-aba30f2285c5b009',
        UserAPI_URL: 'https://mzwtexdjl5.execute-api.eu-west-3.amazonaws.com/dev0/user',
        ImageAnalysisAPI_URL: 'https://7ndh33fsmg.execute-api.eu-west-3.amazonaws.com/dev0/emotion',
        InfoAPI_URL: 'https://g50egm5ig8.execute-api.eu-west-3.amazonaws.com/dev0/info',
        TransactionAPI_URL: 'https://5e4m81hgv2.execute-api.eu-west-3.amazonaws.com/dev0/transaction'
    },
    dev1: {
        AuthURL: 'https://d3o5hrtbl653it.auth.eu-west-3.amazoncognito.com/oauth2/authorize?client_id=3ng1jhbo6oemarms0uc9mhadak&response_type=code&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fcallback',
        CognitoURL: 'https://d3o5hrtbl653it.auth.eu-west-3.amazoncognito.com/oauth2/token',
        RedirectURI: 'http://localhost:3000/callback',
        ClientID: '3ng1jhbo6oemarms0uc9mhadak',
        UploadImageAPI_URL: 'https://9q1r5ay8di.execute-api.eu-west-3.amazonaws.com/dev0/faces-pictures-aba30f2285c5b009',
        UserAPI_URL: 'https://7zmgriuy41.execute-api.eu-west-3.amazonaws.com/dev1/user',
        ImageAnalysisAPI_URL: 'https://cd8jsrtdg9.execute-api.eu-west-3.amazonaws.com/dev1/emotion',
        InfoAPI_URL: 'https://nx35k3unc2.execute-api.eu-west-3.amazonaws.com/dev1/info',
        TransactionAPI_URL: 'https://14bhqtzp60.execute-api.eu-west-3.amazonaws.com/dev1/transaction'
    },
    test: {
        AuthURL: 'https://d3o5hrtbl653it.auth.eu-west-3.amazoncognito.com/oauth2/authorize?client_id=k2ak0o89gb2lop4vntbbcbta9&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fmain.d258l7k8n242lz.amplifyapp.com%2Fcallback',
        CognitoURL: 'https://d3o5hrtbl653it.auth.eu-west-3.amazoncognito.com/oauth2/token',
        RedirectURI: 'https://main.d258l7k8n242lz.amplifyapp.com/callback',
        ClientID: 'k2ak0o89gb2lop4vntbbcbta9',
        UploadImageAPI_URL: 'https://xff36901f0.execute-api.eu-west-3.amazonaws.com/test/test-faces-pictures-aba30f2285c5b009',
        UserAPI_URL: 'https://1qo3sovodc.execute-api.eu-west-3.amazonaws.com/test/user',
        ImageAnalysisAPI_URL: 'https://pmbye2w6z4.execute-api.eu-west-3.amazonaws.com/test/emotion',
        InfoAPI_URL: 'https://592vx710w8.execute-api.eu-west-3.amazonaws.com/test/info',
        TransactionAPI_URL: 'https://lusvfbhzjb.execute-api.eu-west-3.amazonaws.com/test/transaction'
    }
};

const EnvironmentVariables = {
    AuthURL: baseURLs[Environment].AuthURL,
    UserAPI_URL: baseURLs[Environment].UserAPI_URL,
    ImageAnalysisAPI_URL: baseURLs[Environment].ImageAnalysisAPI_URL,
    UploadImageAPI_URL: baseURLs[Environment].UploadImageAPI_URL,
    SongsAPI_URL: baseURLs[Environment].SongsAPI_URL,
    InfoAPI_URL: baseURLs[Environment].InfoAPI_URL,
    EventAPI_URL: baseURLs[Environment].EventAPI_URL,
    TransactionAPI_URL: baseURLs[Environment].TransactionAPI_URL,
    RedirectURI: baseURLs[Environment].RedirectURI,
    CognitoURL: baseURLs[Environment].CognitoURL,
    ClientID: baseURLs[Environment].ClientID
};

export default EnvironmentVariables;