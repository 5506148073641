import React, { useEffect } from 'react';
import TransactionAPI from './TransactionAPI';
import UserAPI from './UserAPI';
import { formatTitleToDB, formatTitleToDisplay } from './Functions';
import { Title, Text } from './Typography';
import { useState } from 'react';

const UserSongsMenu = (componentWidth) => {
    const [songs, setSongs] = React.useState([]);
    const [confirmationMessage,setConfirmationMessage] = useState('');
    
    const loadSongs = async()    => {
        const requestBody = JSON.stringify({
            'idToken': localStorage.getItem('idToken'),
            'request_type': 'songs',
            'action': 'getSessionSongList',
            'session_name': localStorage.getItem('selectedSession')
        });

        
    const jsonData=await UserAPI(requestBody);
    const result = JSON.parse(jsonData.body);
    setSongs([]);
    result.forEach(element => {
        var title = element[0];
        title= formatTitleToDisplay(title);
        var artist = element[1];
        artist=formatTitleToDisplay(artist);
        setSongs(prevSongs => [...prevSongs, {title: title, artist: artist}]);
    });

};
    
    const handleRequest = async (index) => {
        const credits = document.getElementById('creditsInput' + index.toString()).value;
        const title = songs[index].title;
        const artist = songs[index].artist;
        const formattedTitle = formatTitleToDB(title);
        const formattedArtist = formatTitleToDB(artist);
        const requestBody = JSON.stringify({
            'idToken': localStorage.getItem('idToken'),
            'type': 'songrequest',
            'recipient': localStorage.getItem('selectedSession'),
            'song_name': formattedTitle,
            'artist': formattedArtist,
            'credits': parseInt(credits, 10) // Convert credits to integer
        });
        await TransactionAPI(requestBody);
        setConfirmationMessage("Requested!");
        setTimeout(() => {
            setConfirmationMessage('');
        }, 2000);
    }
    
    

    useEffect (() => {
       loadSongs();
    },[]);
    return (
        
            <div style={{ maxWidth: componentWidth }}>
                <div id='confirmationmessage'>{confirmationMessage}</div>
                <div style={{ display: 'flex',  fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px' }}>
                    <div style={{ width: '40%' }}>Title</div>
                    <div style={{ width: '40%' }}>Artist</div>
                    <div style={{ width: '20%' }}></div>
                </div>
                <hr />
                {songs.map((song, index) => (
                    <div key={index} style={{ display: 'flex',  marginTop: '10px' }}>
                        <div style={{ width: '40%' }}>{song.title}</div>
                        <div style={{ width: '40%', display: 'flex', alignItems: 'center' }}>{song.artist}</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                            <button onClick={() => handleRequest(index)}>Request</button>
                            <input id={`creditsInput${index}`} type="text" placeholder={`Credits`} style={{ marginRight: '10px' }} />
                        </div>
                    </div>
                ))}
            </div>
    );
};

export default UserSongsMenu;