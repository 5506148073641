import React from 'react';
import { Title, Text } from './Typography';

const EmotionDisplay = ({ emotions }) => {
    const minFontSize = 12; // Minimum font size
    return (
        <div>
            {Object.entries(emotions).map(([emotionType, confidence], index) => {
                const fontSize = Math.max(minFontSize, confidence);
                return (
                    <div key={index} style={{ fontSize: `${fontSize}px` }}>
                        {emotionType}
                    </div>
                );
            })}
        </div>
    );
};

export default EmotionDisplay;