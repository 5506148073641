import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import EmotionDisplay from './EmotionDisplay';
import UserAPI from './UserAPI';
import InfoAPI from './InfoAPI';
import { Title, Text } from './Typography';
import { formatTitleToDB, formatTitleToDisplay } from './Functions';
import { refreshTime } from '../utils/DisplaySettings';

const EmotionScanner = () => {

    const [showSessionEmotion, setShowSessionEmotion] = useState(false);
    const [emotions, setEmotions] = useState([]);
    const [scanOnGoing, setScanOnGoing] = useState(false);
    const [leaderBoard, setLeaderBoard] = useState(false);
    const [emotionTimeInterval, setEmotionTimeInterval] = useState(3);
    const [impressions, setImpressions] = useState([]);
    const [showSessionImpressions, setShowSessionImpressions] = useState(false);

    const getSessionStatus = async () => {
        const requestBody = JSON.stringify({
          'idToken': localStorage.getItem('idToken'),
          'request_type': 'getsessionstatus'
        });
        // Call API for fetching session status
        const jsonData = await UserAPI(requestBody);
        const result = JSON.parse(jsonData.body);
        console.log('Scan status:', result);
        if (result.scan_ongoing) {
            setScanOnGoing(true);
        } else {
            setScanOnGoing(false);
        }
        return result.scan_ongoing;
      };

    const startstopScan = async () => {
        const requestBody = JSON.stringify({
          'idToken': localStorage.getItem('idToken'),
          'request_type': 'startstopscan'
        });
    
        // Call API for starting or stopping a scan
        const jsonData = await UserAPI(requestBody);
        const result = JSON.parse(jsonData.body);
        if (result.scan_ongoing) {
            console.log('Scan started');
          setScanOnGoing(true);
          scanEmotions();
          scanImpressions();
        } else {
          setScanOnGoing(false);
        }
      };


    const scanEmotions = async () => {
        const scanOnGoing = await getSessionStatus();
        console.log('Scan ongoing:', scanOnGoing);
        if (scanOnGoing) {
            const requestBody = JSON.stringify({
                'idToken': localStorage.getItem('idToken'),
                'request_type': 'event',
                'event_type': 'collectEmotionalReactions'
            });
            // Call API emotion scan 
            const emotionData = await UserAPI(requestBody);
            console.log('Emotion scan data:', emotionData);
            if (emotionData.body) {
                const resultEmotion = JSON.parse(emotionData.body);
                console.log('Emotion SCAN result:', resultEmotion.emotions_counts);
                setEmotions(resultEmotion.emotions_counts);
                setShowSessionEmotion(true);
            }
            // Run the scan again after the specified delay
            setTimeout(scanEmotions, emotionTimeInterval * 1000);
        }


    }

    const scanImpressions = async () => {
        const jsonData = await UserAPI(JSON.stringify({'idToken': localStorage.getItem('idToken'),'request_type': 'getsettings'}));
        const result = JSON.parse(jsonData.body);
        const scanOnGoing = await getSessionStatus();
        
        if (result.timedelay && scanOnGoing) {
            const requestBody = JSON.stringify({
                'idToken': localStorage.getItem('idToken'),
                'request_type': 'event',
                'event_type': 'scan',
                'input_type': 'impressions'
            });
            // Call API emotion scan 
            const impressionsData = await UserAPI(requestBody);
            if (impressionsData.body) {
                const resultImpressions = JSON.parse(impressionsData.body);
                console.log('Impression scan result:', resultImpressions.impressions);

                setImpressions(resultImpressions.impressions);
                setShowSessionImpressions(true);
            }
            // Run the scan again after the specified delay
            setTimeout(scanImpressions, result.timeinterval * 1000);
        }


    }

    
    const loadLeaderBoard = async()    => {
        const requestBody = JSON.stringify({
            'idToken': localStorage.getItem('idToken'),
            'info_type': 'leaderboard'
        });

        
        const jsonData=await InfoAPI(requestBody);
        const result = JSON.parse(jsonData.body);
        setLeaderBoard([]);
        result.forEach(element => {
            const formattedTitle = formatTitleToDisplay(element[3]);
            const formattedArtist = formatTitleToDisplay(element[2]);
            setLeaderBoard(prevLeaderBoard => [...prevLeaderBoard, {user: element[0], points: element[1], request: formattedTitle+' by '+formattedArtist}]);
        });
        setTimeout(() => {
            loadLeaderBoard();
        }, refreshTime);
    };


    const resetLeaderBoard = async()    => {
        const requestBody = JSON.stringify({
            'idToken': localStorage.getItem('idToken'),
            'request_type': 'event',
            'event_type': 'startLeaderBoard'
        });

    await UserAPI(requestBody);
    loadLeaderBoard();
    };

    const songGrant = async(index) => {
        const requestBody = JSON.stringify({
            'idToken': localStorage.getItem('idToken'),
            'request_type': 'event',
            'event_type': 'takesongrequest',
            'user_name': leaderBoard[index].user
        });
        await UserAPI(requestBody);
        loadLeaderBoard();
    }

    useEffect(() => {
        getSessionStatus();
        loadLeaderBoard();
        return () => {
            setScanOnGoing(false);
        };
    }, []);

    return (
        <div>
            

            <div style={{ display: 'flex', gap: '10px' }}>
            <button onClick={startstopScan}>{scanOnGoing ? 'Stop Scan' : 'Start Scan'}</button>
          </div>
        
          {showSessionEmotion && (
            <div>
                {Object.entries(emotions).map(([emotionType, count]) => (
                    count > 0 && (
                        <Text key={emotionType}>
                            {emotionType}: {count} times
                        </Text>
                    )
                ))}
            </div>
            )}
            {showSessionImpressions && (
                <EmotionDisplay emotions={impressions} />
            )}
          <div style={{ margin: '20px 0' }}></div>
            <div>
                <button onClick={() => resetLeaderBoard()}>Reset leader board</button>
                <div style={{ margin: '20px 0' }}></div>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', fontSize: '1.2em', marginBottom: '10px' }}>
                    <div style={{ flex: '1 1 25%' }}>Viewer</div>
                    <div style={{ flex: '1 1 25%' }}>Points</div>
                    <div style={{ flex: '1 1 25%' }}>Request</div>
                    <div style={{ flex: '1 1 25%' }}></div>
                </div>
                <hr />
                {leaderBoard.length > 0 && 
                leaderBoard.map((user, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <div style={{ flex: '1 1 25%' }}>{user.user}</div>
                        <div style={{ flex: '1 1 25%' }}>{user.points}</div>
                        <div style={{ flex: '1 1 25%' }}>{user.request}</div>
                        <button onClick={() => songGrant(index)} style={{ flex: '1 1 25%' }}>Grant</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EmotionScanner;