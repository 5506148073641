import EnvironmentVariables from "../utils/EnvironmentVariables";


const UploadImageAPI= async (filename, file) => {
    const idToken = localStorage.getItem('idToken');
    const response = await fetch(EnvironmentVariables.UploadImageAPI_URL+'/'+filename, {
        method: 'PUT',
        headers: {
            'Content-Type': 'image/jpg',
            'Authorization': `Bearer ${idToken}`
        },
        body: file
    });
    console.log('Upload image response:', response);
}

const AnalyseImageAPI = async (requestBody) => {
    const idToken = localStorage.getItem('idToken');
    try {
        const response = await fetch(EnvironmentVariables.ImageAnalysisAPI_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            },
            body: requestBody
        });
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error calling image analysis API:', error);
        throw error;
    }
};

export default {UploadImageAPI, AnalyseImageAPI};