import React from 'react';
import { useAuth } from './AuthContext';
import UserAPI from './UserAPI';
import { Title, Text } from './Typography';

const Profile = () => {
    const { idToken, accessToken } = useAuth();


    return (
        <div>
        </div>
        );
        }

export default Profile;