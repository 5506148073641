import React from 'react';
import UserAPI from './UserAPI';
import { useState } from 'react';
import { Title, Text } from './Typography';

const Impressions = () => {
    const impressions_types = ["energizing", "amusing", "joyful", "beautiful", "calm", "sad", "dreamy", "tense", "amazing"]
    const [confirmationMessage,setConfirmationMessage] = useState('');

    const sendImpression = async () => {
        const inputElements = document.querySelectorAll('input[type="range"]');
        const impressions = impressions_types.map((type, index) => {
            const inputElement = inputElements[index];
            const value = inputElement ? parseFloat(inputElement.value) : 0;
            return { type, value };
        });
        const bodyData = JSON.stringify({
            'sessionname': localStorage.getItem('selectedSession'),
            'request_type': 'impression',
            'idToken': localStorage.getItem('idToken'),
            ...impressions.reduce((acc, { type, value }) => {
                acc[type] = value;
                return acc;
            }, {})
        });
        const jsonData = await UserAPI(bodyData);
        setConfirmationMessage("Shared with the crowd!");
        setTimeout(() => {
            setConfirmationMessage('');
        }, 2000);
    }
    return (
        <div>
            <button onClick={sendImpression} style={{ marginBottom: '20px' }}>Send Impressions</button>
            <div id='confirmationmessage'>{confirmationMessage}</div>
            {impressions_types.map((type, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                    <label style={{ flex: '0 0 40%', marginRight: '10px' }}>{type}</label>
                    <input type="range" min="0" max="100" step="0.01" defaultValue="0" style={{ flex: '1 0 60%' }} />
                </div>
            ))}
        </div>
    );
};

export default Impressions;