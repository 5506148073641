import { Navigate } from 'react-router-dom';
import { checkAuthStatus } from '../utils/Auth';

const ProtectedRoute = ({ children }) => {
    // const isAuthenticated = checkAuthStatus();
    
    // if (!isAuthenticated) {
    //     return <Navigate to="/login" replace />;
    // }

    // return children;
};

export default ProtectedRoute; 