import React from 'react';
import { useEffect } from 'react';
import { fetchTokens } from '../utils/Auth';
import UserAPI from './UserAPI';
import { Title, Text } from './Typography';

const CallBack = () => {
    const createUser = async () => {
        const idToken = localStorage.getItem('idToken');
        const requestBody = JSON.stringify({
            'idToken': idToken,
            'request_type': 'newuser'
        });
        await console.log('API request:', requestBody);
        // Call API for new user creation
        await UserAPI(requestBody);
    }



    useEffect(() => {
    
    fetchTokens();
    createUser();
    

    }, []);

    return (
        <div>
        </div>
    );
}

export default CallBack;