
const APICall = async (requestBody,url) => {
    const idToken = localStorage.getItem('idToken');
    if (!idToken) {
        console.log('No token found');
        return;
    }
    else {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: requestBody
            });
            const data = await response.json();
            return data;
        } catch (error) {
            await console.error('Error calling API'+url+':', error);
        }
    }
};

export default APICall;